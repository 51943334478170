import { useState, useEffect } from "react";
import {
  createStyles,
  Overlay,
  Title,
  Button,
  Text,
  TextInput,
  LoadingOverlay,
} from "@mantine/core";
import { useInterval, useValidatedState } from "@mantine/hooks";
import { closeAllModals, openModal } from "@mantine/modals";

const Modal = () => {
  const [{ value, valid }, setEmail] = useValidatedState(
    "",
    (val) => /^\S+@\S+\.\S+$/.test(val),
    true
  );
  const [loading, setLoading] = useState(false);

  const join = async () => {
    setLoading(true);
    try {
      // https://bootcamp.uxdesign.cc/custom-google-form-interface-with-reactjs-5d6762d8fa65
      const url = `${process.env.REACT_APP_FORM_URL!}?${process.env
        .REACT_APP_FORM_EMAIL!}=${value}`;

      await fetch(url, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        closeAllModals();
      }, 1000);
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <TextInput
        value={value}
        onChange={(event) => setEmail(event.currentTarget.value)}
        withAsterisk
        error={!valid}
        placeholder="email@example.com"
        label="Your email"
        data-autofocus
        name={process.env.REACT_APP_FORM_EMAIL}
      />
      <Button fullWidth onClick={join} mt="md" disabled={!valid || value === ""}>
        Submit
      </Button>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  background: {
    minHeight: "100%",
    minWidth: "1024px",

    /* Set up proportionate scaling */
    width: "100%",
    height: "auto",

    /* Set up positioning */
    position: "fixed",
    top: 0,
    left: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",

    transition: `background-image 1s ease-in-out`,

    "@media screen and (max-width: 1024px)": {
      left: "50%",
      marginLeft: "-512px",
    },
  },

  container: {
    height: "100vh",
    width: "600px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      width: "300px",
    },
  },

  content: {
    marginTop: "auto",
    marginBottom: `calc(${theme.spacing.xl} * 2)`,
    textAlign: "center",
  },

  title: {
    color: theme.white,
    fontSize: 60,
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan("xs")]: {
      fontSize: 40,
    },
  },

  description: {
    color: theme.white,

    [theme.fn.smallerThan("xs")]: {
      fontSize: theme.fontSizes.md,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,
  },
}));

const random = () => {
  const count = 39;
  const index = Math.max(Math.floor(Math.random() * count), 1);
  return `/images/${index}.jpg`;
};

const App = () => {
  const { classes } = useStyles();
  const [image, setImage] = useState(random());

  const interval = useInterval(() => setImage(random()), 5000);

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, [interval]);

  return (
    <div className={classes.background} style={{ backgroundImage: `url(${image})` }}>
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={0.5}
        zIndex={-1}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Title className={classes.title}>Chunky Bois</Title>
          <Text className={classes.description} size="xl" mt="xl">
            Not only a luxury lifestyle clothing brand, but a way of life.
          </Text>

          <Button
            variant="gradient"
            size="xl"
            radius="xl"
            className={classes.control}
            onClick={() => {
              openModal({
                title: "Join the waitlist",
                children: <Modal />,
              });
            }}
          >
            Join the waitlist
          </Button>
        </div>
      </div>
    </div>
  );
};

export default App;
